import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import './TopBulletSection.css';

const TopBulletSection = ({ scrollToWhatWeDo }) => {
  const [activePhrase, setActivePhrase] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const navigate = useNavigate();

  const aiPhrases = ["Revolutionize", "Transform", "Optimize", "Innovate", "Empower"];

  useEffect(() => {
    const interval = setInterval(() => {
      setActivePhrase((prev) => (prev + 1) % aiPhrases.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleChatButtonClick = () => {
    navigate('/chat');
  };

  return (
    <div className={`minimalist-ai-container ${inView ? 'in-view' : ''}`} ref={ref}>
      <div className='ai-tagline-background'>
        <div className="ai-tagline">
          <h1>We help you</h1>
          <div className="rotating-phrase">
            {aiPhrases.map((phrase, index) => (
              <span key={index} className={index === activePhrase ? 'active' : ''}>
                {phrase}
              </span>
            ))}
          </div>
          <h1>with AI</h1>
        </div>
      </div>

      <div className="top-bullet-container">
        <div className="top-bullet-container-left">
          <div className="top-bullet-container-title">
            <div className="top-bullet-container-left-box">
              <div className="new-tag">Custom</div>
              <h2>Helix Labs</h2>
              <p>Powering Innovation with Custom AI Solutions</p>
              <div className="bottom-button-container">
                {/** 3. On button click, call the function you received from the parent */}
                <button
                  className="talk-to-claude-btn"
                  onClick={scrollToWhatWeDo}
                >
                  See Solutions
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="top-bullet-container-right">
          <div className="top-bullet-container-title">
            <div className="bullet-email-container-bg">
              <div className="bullet-email-container">
                <div className="new-tag">Individuals</div>
                <h2>Helix AI</h2>
                <p>Empowering Individuals With Helix AI</p>
                <div className="bottom-button-container">
                  <button
                    className="get-started-btn"
                    onClick={handleChatButtonClick}
                  >
                    Talk to Helix
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBulletSection;
