import React, { useState } from 'react';
import axios from 'axios';

const DataConsultationForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    businessSize: 'small',
    industry: '',
    dataChallenges: '',
    preferredContact: 'email',
    message: ''
  });

  const [status, setStatus] = useState({
    submitting: false,
    message: '',
    error: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ submitting: true, message: '', error: false });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/data-consultation`, formData);
      alert(response.data.message);
      setFormData({
        name: '',
        email: '',
        company: '',
        phone: '',
        businessSize: 'small',
        industry: '',
        dataChallenges: '',
        preferredContact: 'email',
        message: ''
      });
      // Close form after successful submission
      setTimeout(() => onClose(), 2000);
    } catch (error) {
      alert('An error occurred. Please try again.');
      setStatus({
        submitting: false,
        message: 'An error occurred. Please try again.',
        error: true
      });
    }
  };

  return (
    <div className="consultation-form">
      <h2>Request Data Consultation</h2>
      
      {status.message && (
        <div className={`form-message ${status.error ? 'error' : 'success'}`}>
          {status.message}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-group">
            <label>Name *</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="form-group">
            <label>Email *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Company *</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Phone *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Business Size</label>
            <select
              name="businessSize"
              value={formData.businessSize}
              onChange={handleChange}
            >
              <option value="small">Small (1-50 employees)</option>
              <option value="medium">Medium (51-250 employees)</option>
              <option value="large">Large (251+ employees)</option>
            </select>
          </div>

          <div className="form-group">
            <label>Industry</label>
            <input
              type="text"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Data Challenges</label>
          <textarea
            name="dataChallenges"
            value={formData.dataChallenges}
            onChange={handleChange}
            rows="3"
            placeholder="What are your main data-related challenges?"
          />
        </div>

        <div className="form-group">
          <label>Preferred Contact Method</label>
          <select
            name="preferredContact"
            value={formData.preferredContact}
            onChange={handleChange}
          >
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
        </div>

        <div className="form-group">
          <label>Additional Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            placeholder="Any additional information you'd like to share..."
          />
        </div>

        <div className="form-actions">
          <button type="submit" disabled={status.submitting}>
            {status.submitting ? 'Sending...' : 'Submit Consultation Request'}
          </button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default DataConsultationForm;