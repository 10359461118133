import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Brain, Code, Database, Laptop, BookOpen, Users, LineChart } from 'lucide-react';
import './WhatWeDoPage.css';
import LightComputer from '../../../assets/Light-computer.jpg';
import Helix from '../../../assets/helix.png';
import AnimatedSolutions from './AnimatedSolutions';
import Teamwork from '../../../assets/teamwork.jpg';
import ConsultingSection from './ConsultingSection';

const WhatWeDoPage = () => {
  const navigate = useNavigate();

  const aiSolutions = [
    {
      title: 'AI Product Development',
      description: 'Custom AI solutions designed to address your specific business challenges, from concept to deployment.',
      icon: <Code className="icon blue" />
    },
    {
      title: 'AI Automation',
      description: 'Intelligent automation systems that streamline operations and enhance productivity through AI-powered decision making.',
      icon: <Brain className="icon blue" />
    },
    {
      title: 'Reinforcement Learning',
      description: 'Advanced RL solutions that enable systems to learn and adapt through interaction with their environment.',
      icon: <Brain className="icon blue" />
    },
    {
      title: 'Data Analysis',
      description: 'Comprehensive data analysis services using cutting-edge AI techniques to extract meaningful insights.',
      icon: <LineChart className="icon blue" />
    }
  ];

  const consultingServices = [
    {
      title: 'Data Strategy & Management',
      description: 'Strategic planning and implementation of robust data infrastructure to support your AI initiatives.',
      icon: <Database className="icon purple" />
    },
    {
      title: 'AI Transformation',
      description: 'End-to-end guidance for organizations transitioning to AI-powered operations and decision-making.',
      icon: <Laptop className="icon purple" />
    },
    {
      title: 'AI Training & Education',
      description: 'Comprehensive training programs to empower your team with AI knowledge and skills.',
      icon: <BookOpen className="icon purple" />
    }
  ];

  const handleExploreMoreClick = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  return (
    <div className="what-we-do">
      {/* Introduction Section */}
      <section className="hero">
        <div className="container">
          <div className="hero-content">
            <h1>Transforming Businesses Through AI Innovation</h1>
            <div className="transformation-ptext">
            <p>
              We specialize in developing cutting-edge AI solutions that drive business growth and innovation. Our team of experts combines deep technical expertise with industry knowledge to deliver customized AI solutions that meet your unique needs.
            </p>
            </div>
            <button className="learn-more blue" onClick={() => navigate('/contact')}>
              Contact Us <ChevronRight className="chevron" />
            </button>
          </div>
        </div>
      </section>

     {/* AI Solutions Section */}
     <div className="what-we-do-gradient-block">
     </div>
      <AnimatedSolutions />

      {/* Consulting Services Section */}
      <div className="what-we-do-gradient-block-bottom">
     </div>
        <ConsultingSection />
      {/* Conclusion Section */}
      <section className="conclusion">
        <div className="container">
          <h2>Flexible Solutions for Every Need</h2>
          <p>
            At our core, we believe in the power of adaptability and innovation. Our team is equipped to tackle any AI challenge, whether it's developing custom solutions, implementing existing technologies, or creating entirely new approaches to meet your specific needs. We pride ourselves on our ability to work closely with clients to understand their unique requirements and deliver solutions that exceed expectations.
          </p>
          <button className="learn-more blue" onClick={handleExploreMoreClick}>
            Explore More Solutions <ChevronRight className="chevron" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default WhatWeDoPage;