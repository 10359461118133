import './Navbar.css';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Components/User_auth/AuthContext';
import ProfileIcon from '../../assets/ProfileIcon.png';
import { setLoginStatus } from '../../redux/actions/authActions'; // Adjust the path as needed
import { useDispatch } from 'react-redux';


const Navbar = ({isLoggedIn}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [whatWeDoDropdownOpen, setWhatWeDoDropdownOpen] = useState(false);
  const whatWeDoRef = useRef(null);
  const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const dropdownRef = useRef(null); // Ref to the dropdown for detecting outside clicks
  const { logout } = useAuth(); // Use the logout function from AuthContext
  const dispatch = useDispatch();
  const navigate = useNavigate(); // For programmatic navigation

const handleProfileClick = () => {
    setProfileDropDownOpen(!profileDropDownOpen); // Toggle dropdown visibility
  };
    const handleLinkClick = () => {
        setDropdownOpen(false);
    };
const handleLoginClick = () => {
    dispatch(setLoginStatus(true));
    navigate('/auth');
  };

  const handleGetStartedClick = () => {
    dispatch(setLoginStatus(false));
    navigate('/auth');
  };

  const handleManageAccountClick =() => {
    navigate('/manage-account');
  };




// Toggles the dropdown open state
   const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
   const toggleWhatWeDoDropdown = () => setWhatWeDoDropdownOpen(!whatWeDoDropdownOpen);

// Listen for clicks outside of the dropdown to close it
useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, [dropdownRef]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (whatWeDoRef.current && !whatWeDoRef.current.contains(event.target)) {
      setWhatWeDoDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, [whatWeDoRef]);

  return (
    <div className="top-bar">
      <div className="title gradient__text">Helix AI</div>

      <div className="left-section">
        {isAuthenticated ? (
          // Render Navbar for logged-in users
          <div className="navbar-links desktop-nav">
            <p><Link to="/">Home</Link></p>
            <p><Link to="/pricing">Pricing</Link></p>
            <p><Link to="/chat">Chat</Link></p>
            <p><Link to="/blog">Blog</Link></p>
            {/* Profile dropdown */}
            <div className="profile-dropdown">
              <img src={ProfileIcon} alt="Profile" className="profile-icon" onClick={handleProfileClick} />
              {profileDropDownOpen && (
                <div className="profile-dropdown-content">
                   <button className= "profile-logout-button" onClick={() => {
                       handleManageAccountClick();
                      }}>
                      Manage Account</button>

                </div>
              )}
            </div>
          </div>
        ) : (
          // Render Navbar for non-logged-in users
          <div className="navbar-links desktop-nav">
            <p><Link to="/">Home</Link></p>
            {/* Insert the new "What we do" dropdown here */}
            <div className="what-we-do-dropdown" ref={whatWeDoRef}>
              <p onClick={toggleWhatWeDoDropdown} style={{ cursor: 'pointer' }}>What we do</p>
              {whatWeDoDropdownOpen && (
                <div className="what-we-do-dropdown-content">
                  <p><Link to="/custom-ai-solutions">Building custom AI solutions</Link></p>
                  <p><Link to="/ai-consulting">AI Consulting Services</Link></p>
                  <p><Link to="/data-optimization">Data Analytics</Link></p>
                </div>
              )}
            </div>
            <p><Link to="/pricing">Pricing</Link></p>
            <p><Link to="/chat">Chat</Link></p>
            <p><Link to="/blog">Blog</Link></p>
            <p onClick={handleLoginClick} style={{ cursor: 'pointer' }}>Log in</p> {/* Make this clickable and handle login click */}
            <div className="get-started-container">
            <p onClick={handleGetStartedClick}style={{ cursor: 'pointer' }}>Get Started</p> {/* Make this clickable and handle get started click */}
          </div>
          </div>
        )}

        {/* this section control dropdown. toggleDropDown also allows for dropdown to close when
         when clicked out of bar or going to another link*/}
        <div className="dropdown" ref={dropdownRef}>
          <button onClick={toggleDropdown}>
            {dropdownOpen ? 'Close' : 'Menu'}
          </button>

          {/* Dropdown Content */}
          {dropdownOpen && (
        <div className="dropdown-content">
            <p><Link to="/" onClick={handleLinkClick}>Home</Link></p>

            {/* Insert the new "What we do" dropdown here */}
            <div className="what-we-do-dropdown-mobile">
              <p onClick={toggleWhatWeDoDropdown} style={{ cursor: 'pointer' }}>What we do</p>
              {whatWeDoDropdownOpen && (
                <>
                  <p><Link to="/custom-ai-solutions" onClick={handleLinkClick}>Building custom AI solutions</Link></p>
                  <p><Link to="/ai-consulting" onClick={handleLinkClick}>AI Consulting Services</Link></p>
                  <p><Link to="/data-optimization" onClick={handleLinkClick}>Helping businesses get more out of their data</Link></p>
                </>
              )}
            </div>

            {isAuthenticated ? (
              // Dropdown links for logged-in users
              <>
                <p><Link to="/manage-account" onClick={handleManageAccountClick}>Manage Account</Link></p>
                <p><Link to="/pricing" onClick={handleLinkClick}>Pricing</Link></p>
                <p><Link to="/chat" onClick={handleLinkClick}>Chat</Link></p>
                <p><Link to="/blog" onClick={handleLinkClick}>Blog</Link></p>
              </>
            ) : (
              // Dropdown links for non-logged-in users
              <>
                <p><Link to="/pricing" onClick={handleLinkClick}>Pricing</Link></p>
                <p><Link to="/chat" onClick={handleLinkClick}>Chat</Link></p>
                <p><Link to="/blog" onClick={handleLinkClick}>Blog</Link></p>
                <p><Link to="/auth" onClick={handleLinkClick}>Sign Up</Link></p>
              </>
            )}
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

