import { BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Navbar, CustomAISolutions, AIConsulting, DataOptimization, ChatPage, ChatHistory, NotePage, Blog, BlogList, ManageAccount, NetworkGraph  } from './containers';
import { LandingPage, Contact, AuthPage, Pricing, Success, Cancel, PaymentForm, ProtectedRoute, Popup, About, ForgotPassword, PasswordReset, AnswerQuestion, PrivacyPolicy } from './Components';
import './App.css';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { AuthProvider, useAuth } from './Components/User_auth/AuthContext';
import { Provider } from 'react-redux'; // Import Provider
import store from './redux/store'; // Import your store



const MainContent = () => { // main content is what app.js used to be, it contains the whole app
  //this tracks user to see if they route to chat section, if they do, 100% width navbar is not rendered
  const location = useLocation();
  const { isAuthenticated } = useAuth(); // useAuth hook to check authentication status

 let appClassName = 'App';
  if (location.pathname === '/') {
    appClassName += ' landing-gradient';
  }

  const [sessionId] = useState('unique-session-id');


// if user is not in chat section, render navbar {location.pathname !== '/chat' && <Navbar />}
  return (
  <AuthProvider>
   <Popup /> {/* This is your new Popup component */}
    <div className={appClassName}>
    {!(location.pathname === '/chat' || location.pathname === '/chatPDF') &&
    <Navbar  /> }
      <Routes>
        <Route path="/" element={<LandingPage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/auth/forgotpassword" element={<ForgotPassword/>} />
            <Route path="/auth/reset-password" element={<PasswordReset />} />
            <Route path="/notes" element={<NotePage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/payment" element={<PaymentForm />} />
            <Route path="/blog/:blogId" element={<Blog />} /> {/* New route for blog posts */}
            <Route path="/blog" element={<BlogList />} /> {/* New route for blog posts */}
            <Route path="/about" element={<About />} />
            <Route path="/answer" element={<AnswerQuestion />} /> {/* new route for daily question answering */}
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/success" element={<Success /> }/>
            <Route path="/cancel" element={<Cancel /> }/>
             <Route
                path="/manage-account"
                element={
                  <ProtectedRoute>
                    <ManageAccount />
                  </ProtectedRoute>
                }
              />
              <Route path="/network" element={<NetworkGraph />} /> {/* New route for network graph */}
              <Route path="/custom-ai-solutions" element={<CustomAISolutions />} />
              <Route path="/ai-consulting" element={<AIConsulting />} />
              <Route path="/data-optimization" element={<DataOptimization />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/*<Route path="/terms-of-service" element={<TermsOfService />} />*/}
              {/*<Route path="/refund-policy" element={<RefundPolicy />} /> */}

        </Routes>
    </div>
   </AuthProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}> {/* Wrap your app with Provider */}
    <BrowserRouter>
      <MainContent />
    </BrowserRouter>
    </Provider>
  );
}

export default App;

