import React, { useState, useEffect } from 'react';
import { Database, Laptop, BookOpen, Target, Map, Settings, Cpu, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './ConsultingSection.css';
import Teamwork from '../../../assets/teamwork.jpg';

const ConsultingSection = () => {
  const [activeCategory, setActiveCategory] = useState('strategy');
  const navigate = useNavigate();

  // Auto-rotation effect
  useEffect(() => {
    const categories = ['strategy', 'implementation', 'enablement'];
    const timer = setInterval(() => {
      setActiveCategory(currentCategory => {
        const currentIndex = categories.indexOf(currentCategory);
        const nextIndex = (currentIndex + 1) % categories.length;
        return categories[nextIndex];
      });
    }, 4000); // Switch every 4 seconds

    return () => clearInterval(timer);
  }, []);

  const handleLearnMoreClick = (e) => {
    e.stopPropagation();
    navigate('/ai-consulting');
    window.scrollTo(0, 0);
  };

  const consultingCategories = {
    strategy: {
      title: 'Strategy',
      services: [
        {
          title: 'AI Strategy Development',
          description: 'Develop a comprehensive AI strategy aligned with your business goals and market opportunities.',
          icon: <Target className="category-icon" size={24} />
        },
        {
          title: 'AI Opportunity Mapping',
          description: 'Identify high-impact AI use cases and explore new growth avenues across your organization.',
          icon: <Map className="category-icon" size={24} />
        }
      ]
    },
    implementation: {
      title: 'Implementation',
      services: [
        {
          title: 'Domain-specific Model Development',
          description: 'Design, train, and deploy AI models tailored to your industry\'s unique data and requirements.',
          icon: <Settings className="category-icon" size={24} />
        },
        {
          title: 'Generative AI Solutions Development',
          description: 'Implement cutting-edge generative AI solutions for innovative product offerings and streamlined processes.',
          icon: <Cpu className="category-icon" size={24} />
        }
      ]
    },
    enablement: {
      title: 'Enablement',
      services: [
        {
          title: 'Data Strategy & Management',
          description: 'Strategic planning and implementation of robust data infrastructure to support your AI initiatives.',
          icon: <Database className="category-icon" size={24} />
        },
        {
          title: 'AI Transformation',
          description: 'End-to-end guidance for organizations transitioning to AI-powered operations and decision-making.',
          icon: <Laptop className="category-icon" size={24} />
        },
        {
          title: 'AI Training & Education',
          description: 'Comprehensive training programs to empower your team with AI knowledge and skills.',
          icon: <BookOpen className="category-icon" size={24} />
        }
      ]
    }
  };

  return (
    <section className="consulting-section">
      <div className="consulting-container">
        <div className="consulting-grid">
          {/* Left side - Image */}
          <div className="consulting-image-container">
            <div className="consulting-image-wrapper">
              <img src={Teamwork} alt="Team collaboration" className="consulting-image" />
              <div className="consulting-image-overlay" />
            </div>
          </div>

          {/* Right side - Content */}
          <div className="consulting-content">
            <h2 className="consulting-title">AI Consulting Services</h2>
            <p className="consulting-description">
              Transform your organization with our comprehensive AI consulting services. Our expert team will guide you through every step of your AI journey.
            </p>

            {/* Category tabs */}
            <div className="consulting-tabs">
              {Object.keys(consultingCategories).map((category) => (
                <button
                  key={category}
                  className={`consulting-tab ${activeCategory === category ? 'active' : ''}`}
                  onClick={() => setActiveCategory(category)}
                >
                  {consultingCategories[category].title}
                </button>
              ))}
            </div>

            {/* Services grid */}
            <div className="services-grid">
              {consultingCategories[activeCategory].services.map((service, index) => (
                <div
                  key={index}
                  className={`service-card fade-effect ${
                    activeCategory === 'enablement' ? 'enablement-card' : ''
                  }`}
                >
                  <div className="service-content">
                    <div className="icon-wrapper">{service.icon}</div>
                    <div className="service-details">
                      <h3 className="service-title">{service.title}</h3>
                      <p className="service-description">{service.description}</p>
                      <button onClick={handleLearnMoreClick} className="learn-more-button">
                        Learn more <ChevronRight className="chevron-icon" size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              {/* Render an invisible card if fewer than 3 services */}
              {consultingCategories[activeCategory].services.length < 3 && (
                <div className="service-card fade-effect invisible-card"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingSection;