import './BenefitsSection.css';
import React from 'react';
import Helix from '../../../assets/helix_white.png';

const BenefitsSection = () => {
  const benefits = [
    {
      icon: "fas fa-lightbulb",
      title: "Innovative R&D",
      description: "Harness cutting-edge AI to solve complex industry challenges and drive transformative breakthroughs."
    },
    {
      icon: "fas fa-network-wired",
      title: "AI Ecosystem",
      description: "Build and scale specialized AI-powered companies, each targeting fundamental problems across various sectors."
    },
    {
      icon: "fas fa-rocket",
      title: "Rapid Commercialization",
      description: "Turn AI innovations into market-ready solutions quickly, enabling industries to adopt and benefit from advanced technologies."
    }
  ];


  return (
    <div className="benefits-wrapper">
     <div className="transparent-bg">
      <div className="benefits-purple-continue">
          <img src={Helix} alt="AI Solutions"class="helix-simple" />
      </div>
      <section className="benefits-section">

        <div className="benefits-container">
          <div className="benefits-header">
            <h3>Helix Labs Vision</h3>
            <h2>Building the Future of Applied AI</h2>
            <p>Transforming industries with innovative AI solutions and a unique R&D ecosystem.</p>
          </div>
          <div className="benefits-grid">
            {benefits.map((benefit, index) => (
              <div key={index} className="benefit-item">
                <i className={`${benefit.icon} benefit-icon`}></i>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>

      </section>
        </div>
    </div>
  );
};


export default BenefitsSection;
