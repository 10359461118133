import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import './BusinessSize.css'

const BusinessSizeSection = () => {
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      className={`business-size-section ${inView ? 'in-view' : ''}`}
      ref={ref}
    >
      <div className="size-section-header">
        <h2>Solutions for Every Scale</h2>
        <p>Tailored AI solutions that grow with your business</p>
      </div>

      <div className="business-cards-container">
        <div className="business-card smb">
          <div className="card-content">
            <div className="size-tag">Small Business</div>
            <h3>Growing Business Solutions</h3>

            <div className="features-grid">
              <div className="feature-business-size">
                <h4>Quick Setup</h4>
                <p>Implementation in weeks, not months</p>
              </div>
              <div className="feature-business-size">
                <h4>Flexible Solutions</h4>
                <p>Customized to your needs</p>
              </div>
              <div className="feature-business-size">
                <h4>Scalable</h4>
                <p>Grows with your business</p>
              </div>
              <div className="feature-business-size">
                <h4>Dedicated Support</h4>
                <p>Personalized assistance</p>
              </div>
            </div>

            <button
              className="learn-more-btn"
              onClick={() => navigate('/small-business')}
            >
              Explore SMB Solutions
            </button>
          </div>
        </div>

        <div className="business-card enterprise">
          <div className="card-content">
            <div className="size-tag">Enterprise</div>
            <h3>Enterprise Solutions</h3>

            <div className="features-grid">
              <div className="feature-business-size">
                <h4>Custom Development</h4>
                <p>Built for your requirements</p>
              </div>
              <div className="feature-business-size">
                <h4>Advanced Security</h4>
                <p>Enterprise-grade protection</p>
              </div>
              <div className="feature-business-size">
                <h4>Full Integration</h4>
                <p>Seamless workflow implementation</p>
              </div>
              <div className="feature-business-size">
                <h4>Priority Support</h4>
                <p>24/7 dedicated team</p>
              </div>
            </div>

            <button
              className="learn-more-btn enterprise"
              onClick={() => navigate('/enterprise')}
            >
              Enterprise Solutions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSizeSection;