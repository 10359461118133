// React and related imports
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ChatArea from './ChatArea';
import SinglePDFChatArea from './SinglePDFChatArea';
import UserInput from '../UserInput/UserInput';
import SinglePDFUserInput from '../UserInput/SinglePDFUserInput';
import FileUploader from './Fileuploader';
import ChatPageNavbar from './ChatPageNavbar';
import ChatTypeDropdown from './ChatPageComponents/ChatTypeDropDown/ChatTypeDropdown';
import UserFiles from './UserFiles/UserFiles';
import SliderToggle from './ChatPageComponents/ChatPageSlider/Slider';
import AdvancedQAComponent from './ChatPageComponents/AdvancedQA/AdvancedQAComponent';
import SherpaToolComponent from './ChatPageComponents/SherpaTool/SherpaToolComponent';
import LawsuitTool from './ChatPageComponents/NewTool/LawsuitTool';
import SinglePDFLayout from './ChatPageComponents/SinglePDFLayout/SinglePDFLayout';

// Utilities and hooks
import axios from 'axios';
import { useAuth } from '../../Components/User_auth/AuthContext';

// Assets
import ToolBarArrow from '../../assets/ToolBarArrow.png';
import FilesIcon from '../../assets/Files-icon.png';
import TagsIcon from '../../assets/Tags-icon.png';
import ToolsIcon from '../../assets/tools-icon.png';
import ReturnIcon from '../../assets/Return-icon.png';

// Redux actions
import { clearBlobUrl } from "../../redux/actions/PDFActions";
import { setToolInactive } from '../../redux/actions/toolActions';
import { setSherpaToolInactive } from '../../redux/actions/sherpaToolActions';
import { clearMessages, clearResponses } from "../../redux/actions/chatActions";
import { clearChatPDFMessages, clearChatPDFResponses } from '../../redux/actions/splitChatActions';

// Stylesfchat
import './ChatPage.css';


const ChatPage = ( sessionId ) => {
// Modal and toolbar states
const [isModalOpen, setModalOpen] = useState(false);
const [isToolBarOpen, setIsToolBarOpen] = useState(false);
const [isUploadShown, setIsUploadShown] = useState(false);

// Chat and UI option states
const [selectedOption, setSelectedOption] = useState('Helix');
const [isHelixSearchOn, setIsHelixSearchOn] = useState(false);
const [singlePDFLayout, setSinglePDFLayout] = useState(false);

// File and document states
const [fileBlobUrl, setFileBlobUrl] = useState('');
const [selectedFileId, setSelectedFileId] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const [contextResponse, setContextResponse] = useState('No context');

// Toolbar icon and content states
const [selectedIcon, setSelectedIcon] = useState(null);
const [userFiles, setUserFiles] = useState([]);
const [userTags, setUserTags] = useState([]);
const [userFilesWithinTag, setUserFilesWithinTag] = useState([]);
const [customTools, setCustomTools] = useState([]);
const [selectedTool, setSelectedTool] = useState(null);
const [isLawsuitToolActive, setIsLawsuitToolActive] = useState(false);

// Loading and error states
const [isLoading, setIsLoading] = useState(false);
const [isTagsLoading, setIsTagsLoading] = useState(false);
const [tagsError, setTagsError] = useState(null);
const [filesError, setFilesError] = useState(null);
const [toolsError, setToolsError] = useState(null);

// Redux selectors
const responses = useSelector((state) => state.chat.responses);
const isToolActive = useSelector((state) => state.toolActive.isToolActive);
const blobUrl = useSelector(state => state.pdfBlob.blobUrl);
const isSherpaToolActive = useSelector((state) => state.sherpaTool.isActive);

// Other important variables
const dispatch = useDispatch();
const { token } = useAuth();

//this function toggles the toolbar (opens or closes it based on current state) also sets uploads to false to close the uploads container
  const toggleButton = () => {
    setIsToolBarOpen(!isToolBarOpen);
    setIsUploadShown(false);
  };

const handleFileData = (blobUrl) => {
    setFileBlobUrl(blobUrl);
    setSinglePDFLayout(true);

    // Check if the responses array is not empty
    if (responses.length > 0) {
        const lastResponse = responses[responses.length - 1];

        // Set contextResponse to the 'answer' part of the last response
        setContextResponse(lastResponse.answer);
    } else {
        // Set contextResponse to "no context given" if responses array is empty
        setContextResponse("no context given");
    }
};

const fetchUserFiles = async () => {
    setIsLoading(true);
    setFilesError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-user-files`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUserFiles(response.data);
    } catch (filesError) {
      console.error('Error fetching user files:', filesError);
      setFilesError('Failed to fetch files. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

const handleIconClick = (iconType) => {
    setSelectedIcon(iconType);
    setIsToolBarOpen(true);
      setSelectedFile(null);

    if (iconType === 'files') {
      fetchUserFiles();
    } else if (iconType === 'tags') {
      fetchUserTags();
    }
    else if (iconType === 'customTools') {
      fetchCustomTools();
      }
  };

// Function to handle the click on the three dots button
  const fetchUserTags = async () => {
    setIsTagsLoading(true);
    setTagsError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-user-files-and-tags`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
    setUserTags(response.data.tags);
    } catch (error) {
      console.error('Error fetching user tags:', error);
      setTagsError('Failed to fetch tags. Please try again.');
    } finally {
      setIsTagsLoading(false);
    }
  };
const fetchCustomTools = async () => {
    setIsLoading(true);
    setToolsError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/custom_tools`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCustomTools(response.data);
    } catch (toolsError) {
      console.error('Error fetching custom tools:', toolsError);
      setToolsError('Failed to fetch custom tools. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
// const fetchCustomTools = async () => {
//   setIsLoading(true);
//   setToolsError(null);
//   try {
//     const response = await axios.get(`${process.env.REACT_APP_API_URL}/custom_tools`, {
//       headers: { 'Authorization': `Bearer ${token}` }
//     });
//
//
//     // Filter out any undefined or null values
//     const toolNames = response.data.filter(tool => tool != null);
//
//     // Add the custom Lawsuit Tool to the fetched tools
//     const toolsWithLawsuit = [...toolNames, 'Sherpa (beta)'];
//
//     // Filter out any undefined values again, just to be safe
//     const finalTools = toolsWithLawsuit.filter(tool => tool != null);
//
//     setCustomTools(finalTools);
//   } catch (toolsError) {
//     setToolsError('Failed to fetch custom tools. Please try again.');
//   } finally {
//     setIsLoading(false);
//   }
// };

  return (
    <div>
      <ChatPageNavbar />
        {/* The button and the container */}
      <div className="tool-bar-permanent">
          <div className="tool-bar-icons-background">
            {(singlePDFLayout || isToolActive || isSherpaToolActive || isLawsuitToolActive) && (
          <div
            className="tool-bar-icon-button-box-return"
            onClick={() => {
              setSinglePDFLayout(false);
              setIsToolBarOpen(!isToolBarOpen);
              setIsUploadShown(false);
              setIsLawsuitToolActive(false);
              dispatch(setToolInactive());
              dispatch(clearBlobUrl());
              dispatch(setToolInactive());
              dispatch(setSherpaToolInactive());
              dispatch(clearMessages());
              dispatch(clearResponses());
              dispatch(clearChatPDFMessages());
              dispatch(clearChatPDFResponses());
            }}
          >
            <div className="tool-bar-icon-button-background">
              <img src={ReturnIcon} alt="tool-bar-image-icon" />
              <div className="tool-bar-icons-return">
                <p>Return to chat</p>
              </div>
            </div>
          </div>
        )}
            <div className="tool-bar-icon-button-box" onClick={() => handleIconClick('files')}>
              <div className="tool-bar-icon-button-background">
                <img src={FilesIcon} alt="tool-bar-image-icon" />
                <div className="tool-bar-icons">
                  <p>Files</p>
                </div>
              </div>
            </div>
            <div className="tool-bar-icon-button-box" onClick={() => handleIconClick('tags')}>
              <div className="tool-bar-icon-button-background">
                <img src={TagsIcon} alt="tool-bar-image-icon" />
                <div className="tool-bar-icons">
                  <p>Tags</p>
                </div>
              </div>
            </div>
            <div className="tool-bar-icon-button-box" onClick={() => handleIconClick('customTools')}>
              <div className="tool-bar-icon-button-background">
                <img src={ToolsIcon} alt="tool-bar-image-icon" />
                <div className="tool-bar-icons">
                  <p>Custom Tools</p>
                </div>
              </div>
            </div>
      </div>
      <div className={`slide-out-container ${isToolBarOpen ? 'active' : ''}`}>
        <div className={`content-area ${isToolBarOpen ? 'pulled-out' : ''}`}>
          <UserFiles
            isUploadShown={isUploadShown}
            setModalOpen={setModalOpen}
            onFileSelect={handleFileData}
            singlePDFLayout={singlePDFLayout}
            setSinglePDFLayout={setSinglePDFLayout}
            setSelectedFileId={setSelectedFileId}
            setIsToolBarOpen={setIsToolBarOpen}
            isToolBarOpen={isToolBarOpen}
            selectedIcon={selectedIcon}
            userFiles={userFiles}
            isLoading={isLoading}
            isTagsLoading={isTagsLoading}
            filesError={filesError}
            userTags={userTags}
            fetchUserTags={fetchUserTags}
            userFilesWithinTag={userFilesWithinTag}
            setUserFilesWithinTag={setUserFilesWithinTag}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            fetchUserTags={fetchUserTags}
            customTools={customTools}
            selectedTool={selectedTool}
            setSelectedTool={setSelectedTool}
            fetchCustomTools={fetchCustomTools}
            isLawsuitToolActive={isLawsuitToolActive}
            setIsLawsuitToolActive={setIsLawsuitToolActive}
           />
        </div>
         <button
          className={`chat-toggle-button ${isToolBarOpen ? 'pulled-out-button' : ''}`}
          onClick={toggleButton}
        >
        <img src={ToolBarArrow} alt="Toggle Arrow" />
        </button>
      </div>
      </div>
      {/* Conditionally render the left and right content */}
    {/* Split layout when showFile is true */}
     {singlePDFLayout && (
        <SinglePDFLayout
          fileBlobUrl={fileBlobUrl}
          isHelixSearchOn={isHelixSearchOn}
          setIsHelixSearchOn={setIsHelixSearchOn}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          sessionId={sessionId}
          selectedOption={selectedOption}
          contextResponse={contextResponse}
          selectedFileId={selectedFileId}
        />
      )}
    {isToolActive && (
      <AdvancedQAComponent
        blobUrl={blobUrl}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        sessionId={sessionId}
        selectedFileId={selectedFileId}
      />
    )}
   {/* This is the sherpa Tool*/}
   {isSherpaToolActive && (
      <SherpaToolComponent
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        handleFileData={handleFileData}
        setSelectedFileId={setSelectedFileId}
        sessionId={sessionId}
        selectedOption={selectedOption}
      />
    )}
    {isLawsuitToolActive && (
      <LawsuitTool
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        handleFileData={handleFileData}
        setSelectedFileId={setSelectedFileId}
        sessionId={sessionId}
        selectedOption={selectedOption}
      />
    )}

 {!singlePDFLayout && !isToolActive && !isSherpaToolActive && !isLawsuitToolActive&&(
      <div className="helix-chatPage" id="home">
        <div className="chatPage-topMessage">
          <ChatTypeDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>

        {/* Modal UI for FileUploader */}
        {isModalOpen && (
          <div className="modal-overlay">

            <div className="modal-content">
              <FileUploader />
              <button className="close-modal-btn" onClick={() => setModalOpen(false)}>X</button>
            </div>
          </div>
        )}
      <ChatArea  onFileSelect={handleFileData} setSelectedFileId={setSelectedFileId}/>

      {/* Adding the UserInput component here */}
      {/* 3. Pass down the setModalOpen function */}

          <UserInput
            sessionId={sessionId}
            openModal={() => setModalOpen(true)}
            selectedOption={selectedOption}
          />
    </div>
    )}

    </div>
  );
}

export default ChatPage;