import React, { useState } from 'react';
import './DataOptimization.css';
import Footer from '../../../Components/Footer/Footer';
import DataConsultationForm from './DataConsultationForm';

const DataOptimization = () => {
  const [showConsultationForm, setShowConsultationForm] = useState(false);

  const handleConsultationClick = () => {
    setShowConsultationForm(true);
  };

  const handleCloseForm = () => {
    setShowConsultationForm(false);
  };

  return (
    <div className="data-optimization">
      <header className="hero">
        <div className="hero-content">
          <h1>Drive Growth with Actionable Data Insights</h1>
          <p>Transform Raw Data into Strategic Business Growth</p>
          <button className="cta-button" onClick={handleConsultationClick}>
            Request a Free Data Consultation
          </button>
        </div>
      </header>

      <main>
        <section className="intro">
          <h2>Why Choose Our Data Optimization Services?</h2>
          <p>
            We turn overwhelming data into clear strategies, helping you make informed decisions,
            uncover new growth opportunities, and stay ahead in your industry.
          </p>
        </section>

        <section className="data-services">
          <h2>Our Data Services</h2>
          <div className="service-grid">
            <div className="service-item">
              <h3>Data Strategy Development</h3>
              <p>Accelerate your growth with data strategies tailored to your business objectives.</p>
            </div>
            <div className="service-item">
              <h3>Data Quality Enhancement</h3>
              <p>Make confident decisions with accurate, reliable data.</p>
            </div>
            <div className="service-item">
              <h3>Advanced Analytics</h3>
              <p>Unlock hidden insights with cutting-edge analytical techniques.</p>
            </div>
            <div className="service-item">
              <h3>Data Visualization</h3>
              <p>Make faster decisions with intuitive dashboards and clear reports.</p>
            </div>
          </div>
        </section>

        <section className="benefits">
          <h2>Benefits of Data Optimization</h2>
          <ul>
            <li>Make smarter decisions with real-time data insights.</li>
            <li>Reduce costs by improving operational efficiency.</li>
            <li>Discover new markets and opportunities before competitors.</li>
            <li>Enhance customer satisfaction by understanding their needs.</li>
            <li>Gain a competitive edge in your industry.</li>
          </ul>
        </section>

        <section className="data-cta">
          <h2>Ready to Transform Your Business with Data?</h2>
          <p>
            Don't miss out on the potential hidden in your data. Let's collaborate to drive your
            business forward.
          </p>
          <button className="cta-button" onClick={handleConsultationClick}>
            Schedule Your Free Consultation
          </button>
        </section>
      </main>

      {showConsultationForm && (
        <div 
          className="modal-overlay"
          onClick={(e) => {
            // Only close if clicking the overlay itself, not the modal content
            if (e.target.className === 'modal-overlay') {
              handleCloseForm();
            }
          }}
        >
          <div className="modal-content">
            <DataConsultationForm onClose={handleCloseForm} />
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default DataOptimization;