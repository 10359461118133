import React from 'react';
import './Legal.css';

const PrivacyPolicy = () => {
  return (
     <div className="legal-container">
      <div className="legal-content">
        <div className="legal-content-header">
          <h1 className="legal-title">Helix Labs Privacy Policy</h1>
          <p className="legal-date">Effective Date: [Insert Date]</p>
        </div>
        <div className="legal-content-body">
          {/* The rest of the content remains the same */}
          <section className="legal-section">
            <h2 className="legal-section-title">1. Introduction</h2>
            <p className="legal-text">
              This Privacy Policy ("Policy") describes how Helix Labs ("Company," "we," "our," or "us") collects, uses, and discloses personal information when you use our AI-powered subscription services ("Services"), including document upload and chat features. By accessing or using our Services, you agree to this Policy.
            </p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">2. Information We Collect</h2>
            <h3 className="legal-subsection-title">2.1 Personal Information</h3>
            <p className="legal-text">We collect information that you provide directly to us, including but not limited to:</p>
            <ul className="legal-list">
              <li>Name</li>
              <li>Email address</li>
              <li>Payment information</li>
              <li>Account credentials</li>
            </ul>

            <h3 className="legal-subsection-title">2.2 User Content</h3>
            <p className="legal-text">We collect and store the documents you upload to our platform and the content of your chat interactions.</p>

            <h3 className="legal-subsection-title">2.3 Usage Information</h3>
            <p className="legal-text">We automatically collect certain information about your use of our Services, including:</p>
            <ul className="legal-list">
              <li>Log data (e.g., IP address, browser type, pages visited)</li>
              <li>Device information</li>
              <li>Usage patterns and preferences</li>
            </ul>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">3. Use of Information</h2>
            <p className="legal-text">We use the collected information for the following purposes:</p>
            <ol className="legal-ordered-list">
              <li>To provide, maintain, and improve our Services;</li>
              <li>To process transactions and send related information;</li>
              <li>To respond to your comments, questions, and requests;</li>
              <li>To send technical notices, updates, and security alerts;</li>
              <li>To monitor and analyze trends, usage, and activities;</li>
              <li>To personalize and improve user experience;</li>
              <li>To develop new products and services;</li>
              <li>To comply with legal obligations.</li>
            </ol>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">4. Data Storage and Security</h2>
            <p className="legal-text">
              Your data is stored on secure servers located in [insert location, e.g., "the United States"]. We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.
            </p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">5. Data Retention</h2>
            <p className="legal-text">
              We retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.
            </p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">6. Disclosure of Information</h2>
            <p className="legal-text">We may disclose your information to:</p>
            <ol className="legal-ordered-list">
              <li>Service providers and partners who assist in operating our business;</li>
              <li>Law enforcement or other government entities when required by applicable law;</li>
              <li>Other parties in connection with a company transaction, such as a merger or sale of company assets.</li>
            </ol>
            <p className="legal-text">We do not sell your personal information to third parties.</p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">7. Your Rights and Choices</h2>
            <p className="legal-text">Subject to applicable law, you may have the right to:</p>
            <ol className="legal-ordered-list">
              <li>Access, correct, or delete your personal information;</li>
              <li>Object to or restrict certain processing of your data;</li>
              <li>Data portability (where technically feasible);</li>
              <li>Withdraw consent at any time (where processing is based on consent).</li>
            </ol>
            <p className="legal-text">To exercise these rights, please contact us using the information provided in Section 11.</p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">8. Cookies and Tracking Technologies</h2>
            <p className="legal-text">
              We use cookies and similar tracking technologies to collect and use personal information about you. You may set your browser to refuse all cookies or to indicate when a cookie is being sent.
            </p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">9. Children's Privacy</h2>
            <p className="legal-text">
              Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
            </p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">10. Changes to This Privacy Policy</h2>
            <p className="legal-text">
              We reserve the right to modify this Policy at any time. We will notify you of any changes by posting the new Policy on this page and updating the "Effective Date" at the top. Your continued use of the Services after any modification indicates your acceptance of the updated Policy.
            </p>
          </section>

          <section className="legal-section">
            <h2 className="legal-section-title">11. Contact Information</h2>
            <p className="legal-text">If you have any questions about this Policy, please contact us at:</p>
            <address className="legal-text">
              Helix Labs<br />
              [Insert Address]<br />
              Email: [Insert Email]<br />
              Phone: [Insert Phone Number]
            </address>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;