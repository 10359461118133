import React from 'react';
import { Link } from 'react-router-dom';
import './BulletSection.css';

const BottomBulletSection = () => {
  const services = [
    {
      title: "Custom AI Tools",
      desc: "Tailored solutions for your unique challenges",
      icon: "🛠️",
      benefits: ["Increased efficiency", "Cost reduction", "Competitive advantage"],
      example: "Our AI data extraction tool saved researchers weeks of time.",
      route: "/custom-ai-solutions"
    },
    {
      title: "AI Consulting",
      desc: "Expert guidance to navigate the AI landscape",
      icon: "💡",
      benefits: ["Strategic AI roadmap", "Risk assessment", "Technology selection"],
      example: "We have helped software engineers increase their productivity by showing how to implement already available AI tools",
      route: "/ai-consulting"
    },
    {
      title: "Data Services",
      desc: "Harness the full potential of your data",
      icon: "📊",
      benefits: ["Enhanced data quality", "Actionable insights", "Predictive analytics"],
      example: "We use the latest AI tools to extract data and insights previously thought to be inaccessible",
      route: "/data-optimization"
    }
  ];

  return (
    <>
      <div className="ai-services">
        {services.map((service, index) => (
          <div key={index} className="service-column">
            <div className="service-icon">{service.icon}</div>
            <h2>{service.title}</h2>
            <p className="service-desc">{service.desc}</p>
            <ul className="service-benefits">
              {service.benefits.map((benefit, idx) => (
                <li key={idx}>{benefit}</li>
              ))}
            </ul>
            <div className="service-example">
              <h4>Success Story:</h4>
              <p>{service.example}</p>
            </div>
            <Link to={service.route} className="learn-more-btn">Learn More</Link>
          </div>
        ))}
      </div>

      <div className="ai-footer">
        <p>Curious about AI potential? <a href="/contact">Let's talk</a></p>
      </div>
    </>
  );
};

export default BottomBulletSection;