import React, { useState } from 'react';
import axios from 'axios';
import './ContactPage.css';

const SendIcon = () => (
  <svg className="send-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 2L11 13M22 2L15 22L11 13M11 13L2 9" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success' or 'error'

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact_page`, formData);
      setStatusType('success');
      setStatusMessage('Thank you for reaching out! We will get back to you soon.');
      setFormData({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
      });
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        setStatusMessage('');
        setStatusType('');
      }, 5000);
      
    } catch (error) {
      setStatusType('error');
      setStatusMessage('An error occurred. Please try again.');
      
      // Hide error message after 5 seconds
      setTimeout(() => {
        setStatusMessage('');
        setStatusType('');
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-page">
      <div className="container">
        <div className="page-header">
          <h1>Get in Touch</h1>
          <p>We're excited to discuss how our AI solutions can transform your business.
            Let's start a conversation about your needs.</p>
        </div>

        <div className="contact-content">
          <div className="form-container">
            <div className="contact-card">
              <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-row">
                  <div className="form-group">
                    <label>Name *</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Company</label>
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label>Email *</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Message *</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows={4}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
                >
                  {isSubmitting ? 'Processing...' : (
                    <>
                      Send Message
                      <SendIcon />
                    </>
                  )}
                </button>

                {statusMessage && (
                  <div className={`status-message ${statusType}`}>
                    <span className={statusType === 'success' ? 'check-icon' : 'error-icon'}>
                      {statusType === 'success' ? '✓' : '⚠'}
                    </span>
                    <p>{statusMessage}</p>
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="info-container">
            <div className="contact-card">
              <h3>Contact Information</h3>
              <div className="contact-info">
                <div className="info-item">
                  <span className="info-icon">✉</span>
                  <div className="info-content">
                    <p className="info-label">Email</p>
                    <a href="mailto:contact@helixlabs.com">
                      HelixLabs@gmail.com
                    </a>
                  </div>
                </div>

                <div className="info-item">
                  <span className="info-icon">📍</span>
                  <div className="info-content">
                    <p className="info-label">Address</p>
                    <p className="address">
                      Helix Labs LLC.<br />
                      Edmond<br />
                      Oklahoma<br />
                      United States
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;