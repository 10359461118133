import React, { useRef } from 'react';
import './LandingPage.css';
import HeroSection from './HeroSection/HeroSection.jsx';
import HowTo from './HowTo/HowTo.jsx';
import FeaturesSection from './FeaturesSection/FeaturesSection.jsx';
import CallToAction from './CallToAction/CallToAction.jsx';
import EmailCaptureForm from './EmailCaptureForm/EmailCaptureForm.jsx';
import IconSection from './Icon/IconSection.jsx';
import BottomBulletSection from './BulletSection/BottomBulletSection.jsx';
import TopBulletSection from './BulletSection/TopBulletSection.jsx';
import BenefitsSection from './BenefitsOfAI/BenefitsSection.jsx';
import AboutUsSection from './AboutUsSection/AboutUsSection.jsx';
import Footer from '../Footer/Footer.jsx';
import BusinessSizeSection from './BusinessSize/BusinessSize.jsx';
import WhatWeDoPage from './WhatWeDo/WhatWeDoPage.jsx';

const LandingPage = () => {
  // 1. Create a ref for the "WhatWeDo" section
  const whatWeDoRef = useRef(null);

  // 2. Define a function to scroll to this ref
  const scrollToWhatWeDo = () => {
    if (whatWeDoRef.current) {
      whatWeDoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className="landing-container">
        <HeroSection />

        {/* 3. Pass the scroll function to the child component as a prop */}
        <TopBulletSection scrollToWhatWeDo={scrollToWhatWeDo} />

        <BenefitsSection />

        {/* 4. Wrap the targeted section in a div that holds the ref */}
        <div ref={whatWeDoRef}>
          <WhatWeDoPage />
        </div>

        <BottomBulletSection />
        <BusinessSizeSection />
        <EmailCaptureForm />
        <FeaturesSection />
        <IconSection />
        <CallToAction />
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
