import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../Components/User_auth/AuthContext';
import axios from 'axios';
import '../ToolBar.css';

export const ChatHistory = ({ selectedChat, handleChatSelect }) => {
  const [chatHistories, setChatHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    fetchChatHistories();
  }, []);

  const fetchChatHistories = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tool_history`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { tool_name: 'lawyer_tool' }
      });
      setChatHistories(response.data);
    } catch (err) {
      console.error('Error fetching chat histories:', err);
      setError('Failed to fetch chat histories. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="chat-history-container">
      <h4>Chat History</h4>
      {isLoading ? (
        <p>Loading chat histories...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="chat-histories">
          {chatHistories.length > 0 ? (
            chatHistories.map((chat) => (
              <div
                key={chat.session_id}
                className={`chat-container ${selectedChat === chat.session_id ? 'selected' : ''}`}
                onClick={() => handleChatSelect(chat.session_id)}
              >
                <span className="chat-title">{chat.topic || 'Untitled Chat'}</span>
                <span className="chat-date">{new Date(chat.last_updated).toLocaleDateString()}</span>
              </div>
            ))
          ) : (
            <p>No chat histories found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatHistory;