import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import './ChatArea.css'
import { useAuth } from '../../Components/User_auth/AuthContext';


const ChatArea = ({ onFileSelect, setSelectedFileId }) => {
  const [inlineViewerUrl, setInlineViewerUrl] = useState(''); // State to hold the inline viewer URL
  const { isAuthenticated, token } = useAuth(); //authentication
  const [isLoading, setIsLoading] = useState(false);//used for disabling the source button to prevent people from loading tons of files in
  const chatEndRef = useRef(null);// This is the reference to the bottom of the chat area to allow scrolling to bottom
  const [showPDF, setShowPDF] = useState(false); //State to hold the PDF viewer status
  const [showSources, setShowSources] = useState(true);
  const [isImage, setIsImage] =useState(false);// state to hold the Source status
  const [chatBlobUrl, setChatBlobUrl] = useState(''); // State to hold the inline viewer URL
  const messages = useSelector((state) => state.chat.messages);
  const responses = useSelector((state) => state.chat.responses);

// function to scroll to chatEndRef when response shows up. used in the UseEffect
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

//use effect for the scroll to the bottom. you can choose whether it is response or message based on the array at the end [responses]
  useEffect(() => {
    scrollToBottom();
  }, [responses]); //dependacy array for responses

  // Function to open the PDF viewer
  const openPDF = () => {
    setShowPDF(true);
  };
 // Function to close the PDF viewer
  const closePDF = () => {
    setShowPDF(false);
  };
  // Function to toggle the sources
  //the setShowSources(!showSources) is a toggle function that changes the state of showSources to the opposite of what it was
  //the if statement is for if showSources is true it makes the PDF not shown and makes the Loading block disappear
   const toggleSources = () => {
    setShowSources(!showSources );
     if (showSources) {
    setShowPDF(false);
    setIsLoading(false);

  }
  };
  //This UseEffect is for cleanup.
   useEffect(() => {
        // This function is the cleanup function
        return () => {
            setIsLoading(false);
        };
    }, [showSources]); // Runs the cleanup when showSources changes


  //This function is for when the user clicks the PDF (big and important function)
  const handleSourceClick = async (event) => {
    if (isLoading) return; // Disable click when loading
    event.preventDefault(); //this prevents the default action of opening in anther tab
    setShowPDF(true); //sets showing the PDF to true
    setIsLoading(true); // Set loading state to true

    // Find the anchor tag (if any) that was clicked
    let target = event.target;
    while (target != null && target.nodeName !== "A") {
        target = target.parentNode;
    }

    // If an anchor was clicked, extract and use its href
    if (target && target.nodeName === "A") {
        const fileName = new URLSearchParams(new URL(target.href).search).get('file_name');

      if (fileName) {
        const lowerCaseFileName = fileName.toLowerCase();
        const isImageFile = lowerCaseFileName.endsWith('.png') || lowerCaseFileName.endsWith('.jpg') || lowerCaseFileName.endsWith('.jpeg');
        const isPDFFile = lowerCaseFileName.endsWith('.pdf'); // Check if it's a PDF file

        setIsImage(isImageFile); // Set the isImage state
        setShowPDF(isPDFFile); // Set showing the PDF only if it's a PDF file

        console.log(`File Name: ${fileName}, Is Image: ${isImageFile}, Is PDF: ${isPDFFile}`);

        // Other code...
        setIsLoading(true); // Set loading state to true
       }
        // Retrieve the JWT token from storage

        try {
            // Axios GET request
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/download-file`, {
                params: { file_name: fileName },
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob' // Important for handling binary data like PDF
            });

            // Assuming you have a mechanism to handle the blob response (e.g., displaying the PDF)
            // Example: Create a URL for the blob and set it for the inline viewer
            const blobUrl = URL.createObjectURL(response.data);
            setChatBlobUrl(blobUrl);
            setSelectedFileId(fileName);
            setInlineViewerUrl(blobUrl);
            setIsLoading(false);
        } catch (error) {
            // Handle any errors
            console.error('Error fetching file:', error);
        } finally {
            setIsLoading(false);
            // Additional error handling as needed
        }
    }
};

  const isSherpaToolActive= useSelector((state) => state.sherpaTool.isActive);



  return (
    <div className="chatArea">
      {messages.length === 0 && (
        <div className="top-message">
          <p>Query your knowledge base</p>
        </div>
      )}
      {messages.map((msg, index) => (
        <div key={index} className="message-group">
          <div className="user-message">
            <p><span className="user-text">You:</span> {msg}</p>
          </div>
          {responses[index] ? (
            <div className="ai-message">
              <p><span className={isSherpaToolActive ? "sherpa-text" : "helix-text"}>{isSherpaToolActive ? 'Sherpa' : 'Helix'}:</span></p>
              <ReactMarkdown>{responses[index].answer}</ReactMarkdown>
              <button className="source-button" onClick={toggleSources}>
                Sources <span>{showSources ? '▲' : '▼'}</span>
              </button>
              {showSources && (
                <div className="source-container" onClick={handleSourceClick}>
                  {isLoading ? (
                    <div className="loading-pdf"><p>Loading PDF...</p></div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: responses[index].sources }} />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="ai-message loading">
              <p><span className={isSherpaToolActive ? "sherpa-text" : "helix-text"}>{isSherpaToolActive ? 'Sherpa' : 'Helix'}:</span> Loading response...</p>
            </div>
          )}
        </div>
      ))}
      {/* Conditional rendering for PDF viewer */}
     {showPDF && inlineViewerUrl && (
      isImage ? (
        // Display for image
        <div className="image-viewer">
          <div className="close-button-container">
           <button className="close-button" onClick={closePDF}>Close</button>
           <button className="chat-single-button" onClick={() => onFileSelect(chatBlobUrl)}>Chat?</button>


          </div>
          <img src={inlineViewerUrl} alt="Source Image" style={{ width: '100%', height: 'auto' }} />
        </div>
      ) : (
        // Existing display for PDF
        <div className="pdf-viewer">
          <div className="close-button-container">
           <button className="chat-single-button" onClick={() => onFileSelect(chatBlobUrl)}>Chat?</button>
            <button className="close-button" onClick={closePDF}>Close</button>

          </div>
          <iframe
            src={inlineViewerUrl}
            style={{ width: '100%', height: '500px' }}
            title="Inline Viewer"
          ></iframe>
        </div>
      )
    )}
      <div ref={chatEndRef} />
    </div>
  );
};
export default ChatArea;

//orignal that works but no iframe
//const ChatArea = ({ messages, responses }) => (
//  <div className="chatArea">
//    {messages.map((msg, index) => (
//      <div key={index}>
//        <p><span className="user-text">You:</span> {msg}</p>
//        {responses[index] && (
//          <div>
//            <p><span className="helix-text">Helix:</span> {responses[index].answer}</p>
//            <div>
//              Sources: <div dangerouslySetInnerHTML={{ __html: responses[index].sources }} />
//            </div>
//          </div>
//        )}
//      </div>
//    ))}
//  </div>
//);
//
//export default ChatArea;
