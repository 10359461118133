import React, { useState, useEffect } from 'react';
import { ChevronRight, Code, Brain, LineChart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './AnimatedSolutions.css';
import LightComputer from '../../../assets/Light-computer.jpg';
import Helix from '../../../assets/helix.png';

const AnimatedSolutions = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const navigate = useNavigate();

  const aiSolutions = [
    {
      title: 'AI Product Development',
      description: 'Build and deploy custom AI solutions that align with your unique goals. From prototype to launch, we will help you unlock real value quickly.',
      icon: <Code className="icon blue" />
    },
    {
      title: 'AI Automation',
      description: 'Reduce manual workload and supercharge efficiency with intelligent, machine-learning-driven automation. Focus on what truly matters—innovation.',
      icon: <Brain className="icon blue" />
    },
    {
      title: 'Reinforcement Learning',
      description: 'Let your systems learn by doing. Our RL solutions adapt to changing conditions, continually optimizing performance in real time.',
      icon: <Brain className="icon blue" />
    },
    {
      title: 'Data Analysis',
      description: 'Uncover hidden insights with advanced AI analytics. We turn raw data into actionable intelligence for smarter decision-making.',
      icon: <LineChart className="icon blue" />
    }
  ];

  const handleSelect = (index) => {
    if (index === selectedIndex) return;
    setIsPaused(true);
    setIsAnimating(true);
    setSelectedIndex(index);
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleContactClick = () => {
    navigate('/contact');
  };

  const handleLearnMoreClick = (e) => {
    e.stopPropagation(); // Prevent triggering the card selection
    navigate('/custom-ai-solutions');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      setIsAnimating(true);
      setSelectedIndex((current) => (current + 1) % aiSolutions.length);
      setTimeout(() => setIsAnimating(false), 300);
    }, 6000);

    const pauseTimeout = setTimeout(() => {
      setIsPaused(false);
    }, 30000);

    return () => {
      clearInterval(interval);
      clearTimeout(pauseTimeout);
    };
  }, [isPaused, aiSolutions.length]);

  return (
    <div className="container-solutions">
      <div className="solutions-header">
        <div className="solutions-header-content">
          <h2>AI Solutions</h2>
          <p className="section-intro">
            Our AI solutions are designed to help businesses leverage the power of artificial intelligence
            to solve complex problems and drive innovation. Whether you're looking to automate processes,
            analyze data, or develop custom AI products, we have the expertise to help you succeed.
          </p>
        </div>
        <div className="solutions-header-image">
          <img src={LightComputer} alt="AI Solutions" />
        </div>
      </div>

      <div className="solutions-layout">
        <div className="solutions-list">
          <div className="list-container">
            {aiSolutions.map((solution, index) => (
              <div
                key={index}
                onClick={() => handleSelect(index)}
                className={`list-item ${selectedIndex === index ? 'active' : ''}`}
              >
                <div className="list-item-content">
                  <span className="list-item-title">{solution.title}</span>
                  <ChevronRight className={`chevron-icon ${selectedIndex === index ? 'rotated' : ''}`} />
                </div>
              </div>
            ))}
            <div
              onClick={handleContactClick}
              className="list-item contact-button"
            >
              <div className="list-item-content">
                <span className="list-item-title">Contact Us</span>
                <ChevronRight className="chevron-icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="solution-card-container">
          <div className={`solution-card ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            <img src={Helix} alt="Helix Background" className="card-helix-background" />
            <div className="card-content-what-we-do">
              <div className="card-icon">
                {aiSolutions[selectedIndex].icon}
              </div>
              <h3 className="card-title">
                {aiSolutions[selectedIndex].title}
              </h3>
              <p className="card-description">
                {aiSolutions[selectedIndex].description}
              </p>
              <button onClick={handleLearnMoreClick} className="learn-more-button">
                Learn More <ChevronRight className="chevron-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedSolutions;